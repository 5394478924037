/* todo: migrate these elsewhere */

.org-basic-data-group {
    border: 1px solid grey;
    padding: 5px;
}

.org-basic-data-group h3 {
    margin: 0 0 5px 0;
    font-size: 100%;
    font-weight: bold;

}
.org-basic-data-group h4 {
    margin: 5px 0 0 0;
    font-size: 95%;
    font-weight: bold;
}

.org-basic-data-group input {
    margin: 5px 0 0 0;
}

.org-basic-data-group p {
    font-size: 85%;
}

.org-basic-data-group label, p {
    margin: 0;
}
.org-basic-data-group label:not(.org-basic-data-group-title) {
    font-size: 85%;
}
.org-basic-data-group input[type=text] {
    width: 300px;
    max-width: 100%;
    margin: 0 3px 0 0;
}
.org-basic-data-group button {
    padding-top: 2px;
    margin-bottom: 3px;
}
.org-basic-data-message, .org-basic-data-notes {
    font-size: 85%;
    font-weight: normal;
}
