@import "common_styles.scss";

.page-settings-block {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    //gap: 10px;
    margin-bottom: 30px;
    background-color: #eee;
    padding: 10px;

    div {
        flex: 100%;
    }

    h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child {
        margin-top: 0;
        width: 100%;
    }

    .page-settings-sub-block {
        flex: 252px;
    }

    input[type=text] {
        height: 31px;
        padding: 3px 10px;
        border-radius: $izele-border-radius;
        border-width: 1px;
    }
}

// This is the new (2020) style form stuff
.input-text-label {
    margin-bottom: 20px;

    width: 100%;
    background-color: rgba(37, 31, 56, 0.05);
    padding: 5px;
    border-bottom: 1px solid $izele-blue;

    span:first-of-type {
        color: $izele-blue;
        display: inline-block;
        font-weight: normal;
        font-size: 120%;
    }

    span:nth-of-type(2) {
        margin-left: 10px;
    }

    input[type=text], input[type=password], input[type=email], textarea {
        width: 100%;
        background-color: rgba(0, 0, 0, 0);
        font-size: 120%;
        border: 0;

        &:before {
            content: "\A";
        }

        &:focus {
            outline: none;
            border: 0;
        }
    }

    input[type=checkbox] {
        margin: 0 5px 0 0;
    }
}

// Izele dark blue button
.btn-izele {
  color: #ffffff;
  background-color: #251F38;
  border-color: #130269;

  &:hover, &:focus, &:active, &.active, .dropdown-toggle.btn-izele {
    color: #ffffff;
    background-color: #49247A;
    border-color: #130269;
  }

  &:active, &.active, .dropdown-toggle.btn-izele {
    background-image: none;
  }

  &.disabled, &[disabled], fieldset[disabled] .btn-izele, &.disabled:hover, &[disabled]:hover,
  fieldset[disabled] &:hover, &.disabled:focus, &[disabled]:focus,
  fieldset[disabled] &:focus, &.disabled:active, &[disabled]:active,
  fieldset[disabled] &:active, &.disabled.active, &[disabled].active,
  fieldset[disabled] &.active {
    background-color: #251F38;
    border-color: #130269;
  }

  &.badge {
    color: #251F38;
    background-color: #ffffff;
  }
}

// Izele dark green button
.btn-izele-green {
  color: #ffffff;
  background-color: $izele-green;
  border-color: $izele-green;

  &:hover, &:focus, &:active, &.active, .dropdown-toggle.btn-izele {
    color: #ffffff;
    background-color: #01994D;
    border-color: #01994D;
  }

  &:active, &.active, .dropdown-toggle.btn-izele {
    background-image: none;
  }

  &.disabled, &[disabled], fieldset[disabled] .btn-izele, &.disabled:hover, &[disabled]:hover,
  fieldset[disabled] &:hover, &.disabled:focus, &[disabled]:focus,
  fieldset[disabled] &:focus, &.disabled:active, &[disabled]:active,
  fieldset[disabled] &:active, &.disabled.active, &[disabled].active,
  fieldset[disabled] &.active {
    background-color: #709181;
    border-color: #709181;
  }

  &.badge {
    color: $izele-green;
    background-color: #ffffff;
  }
}


//.sliding-switch {
//  // based on https://codepen.io/mburnette/pen/LxNxNg
//  // format: <div class="sliding-switch"><checkbox with id><label for="checkboxes id">Translatable text</label></div>
//
//  input[type=checkbox]{
//    position: absolute;
//    height: 0; width: 0;
//    visibility: hidden;
//  }
//
//  label {
//      cursor: pointer;
//      text-indent: -9999px;
//      width: 50px;
//      height: 25px;
//      background: $standard-light-grey;
//      display: block;
//      border-radius: 25px;
//      position: relative;
//  }
//
//  label:after {
//      content: '';
//      position: absolute;
//      top: 2px;
//      left: 2px;
//      width: 21px;
//      height: 21px;
//      background: #fff;
//      border-radius: 21px;
//      transition: 0.3s;
//  }
//
//  input:checked + label {
//      background: $izele-blue;
//  }
//
//  input:checked + label:after {
//      left: calc(100% - 3px);
//      transform: translateX(-100%);
//  }
//}
