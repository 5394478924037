@import "_variables.scss";

html {
    height: 100%;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;

    padding-top: 47px;  /* todo: stop using this? */
    height: 100%;
    width: 100%;
}

/* Bootstrap tooltip fix */
.tooltip-inner {
    max-width: 650px;
}