@import "./_variables.scss";

#main-nav {
  min-height: 34px;
  height: 47px;
  border-bottom: 1px solid #006634;

  background-color: #006634 !important;
  background-image: linear-gradient(#008F49, #006634) !important;

  .container {
    @media #{$bootstrap-break-sm} {
      padding: 0;
    }
  }

  .navbar-header {
    width: 100%;

    @media #{$bootstrap-break-sm} {
      margin: 0;
    }
  }

  .navbar-brand {
    margin: 1px 10px 0 0;
    padding: 0;

    img {
      border: 1px solid #ffffff;
      width: 44px;
      height: 44px;

      @media #{$bootstrap-break-sm} {
        width: 31px;
        height: 31px;
      }
    }

    @media #{$bootstrap-break-sm} {
      margin: 1px 8px 0 4px;
    }
  }

  .navbar-search {
    float: left;
    margin-top: 9px;
    width: 350px;

    @media #{$bootstrap-break-sm} {
      margin-top: 6px;
    }

    @media screen and (max-width: 395px) {
        width: 215px;
    }
  }

  @media #{$bootstrap-break-sm} {
    height: 34px;
  }

}

.main-nav-controls {
  position: absolute;
  top: 0; right: 15px;
  height: 47px;
  color: white;
  font-size: 120%;
  cursor: pointer;

  display: flex;
  align-items: center; /* align vertical */
  z-index: 9000;

  @media #{$bootstrap-break-sm} {
    height: 33px;
    right: 3px;
  }

  .main-nav-block {
    float: right;
    text-align: right;
  }
  .main-nav-block:not(:first-of-type) {
    margin-left: 10px;
  }

  a {
    color: white;
  }

  div.dropdown {
    margin-top: 1px;

    span.name {
      @media #{$bootstrap-break-sm} {
        display: none;
      }
    }

    ul {
      svg {
        margin-right: 5px;
      }

      .main-nav-users-pages {
        div {
          display: inline-block;
          width: 20px;

          img {
            max-width: 20px;
            max-height: 20px;
            border-radius: 3px;
          }
        }
      }
    }
  }

  .forum-link {
    display: inline-block;

    img {
      width: 28px;
      height: 28px;
      border: 1px solid white;

      @media #{$bootstrap-break-sm} {
        width: 24px;
        height: 24px;
      }
    }

  }
}
