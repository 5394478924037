.modal-header {
    padding: 5px;
}
.modal-header h4 {
    margin: 2px 0 0 8px;
}

.hover-box-shadow:hover {
    box-shadow: 0 0 8px 1px rgba(0,0,0,0.75);
}

/* Typeahead/search styles ********************************************************************************************/
/* todo: fix references to these two and remove */
.tt-message {
    padding: 1px 5px;
    color: #707070;
}

.tt-menu {
    border: 1px solid gray;
    width: 100%;
    background-color: #FFF;
}

.org-search-container {
    width: 100%;
    margin-bottom: 1px;
    padding: 2px 0;
    cursor: pointer;
}

.org-search-logo {
    float: left;
    width: 50px;
    height: 50px;
    margin-left: 2px;
}

.org-search-detail {
    margin-left: 54px;
}
.org-search-detail p {
    margin: 0;
}
.org-search-detail i, .org-search-detail p:first-child + p {
    font-size: 85%;
}


.org-info-block {
    position: relative;
    margin: 0;
}

.org-info-block div:first-child {
    position: static;
    margin: 0 7px 7px 0;
}

.org-info-block-icon {
    color: grey;
    float: right;
    cursor: pointer;
    top: 1px;
    right: 1px;
}
.org-info-block-icon span:hover {
    color: #ffd900;
}

.org-no-data-message {
    font-size: 85%;
    color: grey;
}

.org-editor {
    background-color: #FFF;
    position: absolute; top: 0; left: 0; right: 0; bottom: 0;
    z-index: 1000;
}
.org-editor textarea {
    width: 100%;
    height: 2em;
    resize: none;
    border: 1px solid grey;
    padding: 3px;
    background-color: white;
    z-index: 9999;
    outline: none !important;  /* chrome blue glow doesn't extend around controls*/
}

.org-editor div:first-child {
    position: absolute;
    top: -27px;
    right: 0;
    padding: 2px 4px;
    border: 1px solid grey;
    border-bottom: 1px solid white;
    background-color: white;
    z-index: 99999;  /* to stop grammarly fucking with the layouts */
}
.org-editor > div button:not(:first-child) {
    margin-left: 3px;
}

.relationship-marker {
    padding: 5px;
    margin-right: 5px;
    cursor: default;
}

.relationship-manager h5 {
    font-weight: bold;
}


/* User management styles *********************************************************************************************/
.login-table {
    width: 100%;
}
.login-table input[type='text'], .login-table input[type='email'], .login-table input[type='password'] {
    width: 100%;
}
.login-table input[type='checkbox'] {
    margin-left: 8px;
}
.login-table td {
    padding-top: 5px;
}

.email-primary, .email-verified, .email-unverified {
    font-size: 80%;
    margin-left: 10px;
}
.email-verified {
    color: green;
}
.email-unverified {
    color: red;
}

/* Main org page styles ***********************************************************************************************/
.org-feed {
    overflow-y: scroll;
}

.org-tabs-content {
    margin-top: 5px;
    width: 100%;
    min-height: 50%;
}

.org-left-sidebar {
    margin-top: 50px;
}

.izele-terms {
    font-size: 90%;
    color: grey;
    text-align: center;
    margin: 20px 0 0 0;
}

.izele-terms-landing-page {
    margin: 10px 0 0 0;
    color: white;
}

.izele-terms-landing-page a {
    color: white;
    margin: 0 8px 0 8px;
}

.upload-drop-zone {
    border: 2px dashed grey;
    padding: 20px;
    text-align: center;
    color: grey;
    cursor: pointer;
}

.upload-image-component h4 {
    display: inline-block;
    float: left;
    margin: 10px 0 5px 0;
}
.upload-image-component button {
    /*float: right;*/
    margin: 5px 0 5px 5px;
}
.upload-image-component .text-danger {
    margin-bottom: 5px;
}

.org-image-list {
    width: 80px;
    height: 80px;
    float: left;
    border: 1px solid grey;
    margin: 0 5px 5px 0;
    cursor: pointer;
}
.org-image-list img {
    max-width: 100%;
    max-height: 100%;
}
.org-image-list-selected, .org-image-list:hover {
    box-shadow: 0 0 4px 1px rgba(0,0,0,0.75);
}


/* Organisation news feed *********************************************************************************************/
.org-feed-messages {
    text-align: center;
    padding: 5px;
}

.org-no-posts {
    text-align: center;
}

/* Mapping tab ********************************************************************************************************/
.stretched-container {
    position: relative;
}

#main-map {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#map-popup-content {
    min-height: 30px;
}

.map-popup-left {
    max-width: 60px;
}
.map-popup-left img {
    padding-right: 10px;
    max-width:50px !important;
    max-height:50px;
}

.panel-title {
    font-size: 100%;
}

.panel-heading {
    padding: 5px;
    height: 26px;
}

.panel-body {
    padding: 10px;
}


/* Some useful common classes *****************************************************************************************/
.std-box {
    padding: 3px
}

.std-box-outline {
    border: 1px solid grey;
    padding: 5px;
}

.no-blue-glow {
    border: none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.rounded {
    border-radius: 4px;
}

.hover-glow:hover {
    text-shadow: 0 0 8px #999;
}

/* this is in the old modal code*/
.close-modal-abs {
    position: absolute;
    top: 0; right: 5px;
}


/* Media queries ******************************************************************************************************/
@media (max-width: 992px) {
    .list-group-item-heading {
        font-size: 90%
    }

    .list-group-item-text {
        font-size: 80%;
    }

    .home-main {
        min-height: 200px;
    }

}

@media (max-width: 768px) {
    .org-main-container {
        padding: 0;
    }

    .tab-control-button {
        font-size: 80%;
        padding: 1px 2px;
    }
}

@media (max-width: 480px) {
    body {
        padding-top: 36px;
    }

    .list-group-item-heading {
        font-size: 70%
    }

    .tab-control-button {
        font-size: 80%;
        padding: 1px 2px;
    }
}

