.page-about-panel {
  .panel-heading {
    h4 {
      margin: -2px 0 0 0;
      font-size: 110%;
    }
  }

  .panel-body {
    margin: 0;
  }

}


.page-edit-sub-tabs {
    font-size: 95%;
    margin-bottom: 5px;

    li {
        a {
            padding: 2px 5px;
        }
    }
}
