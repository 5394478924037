.map-icon-pages {
  div {
    width: 48px;
    height: 64px;

    //background: center no-repeat;
    position: relative;

    > svg {
      width: 100% !important;
      height: 100% !important;
    }

    div {
      display: block;
      position: absolute;
      width: 42px;
      height: 42px;
      left: 3px;
      top: 3px;
      border-radius: 50%;
      background: white;
      overflow: hidden;

      img {
        width: 100%;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.fa-icon-marker {
  div {
    width: 36px;
    height: 48px;
    background: center no-repeat;
    position: relative;

    > svg {
      width: 100% !important;
      height: 100% !important;
    }

    div {
      position: absolute;
      width: 30px;
      height: 30px;
      left: 3px;
      top: 3px;
      border-radius: 50%;
      background: white;
      overflow: hidden;

      svg {
        display: block;
        position: absolute;
        width: auto !important;
        height: 80% !important;
        max-width: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.map-icon-pages-popup {
  display: block;
  width: 230px;
  font-size: 12px;

  img {
    float: left;
    max-width: 70px; max-height: 70px;
    margin-right: 10px;
  }

  p {
    margin: 0;
    overflow: hidden;

    span:first-of-type {
      font-size: 140%;
    }

    span:not(:first-of-type) {
      font-size: 90%;
      font-style: italic;
    }
  }

  .activities-for-popup {
    margin-top: 5px;

    > div {
      display: inline-block;
      position: relative;
      margin-right: 5px;
      border: 1px solid black;
      width: 30px;
      height: 30px;
      border-radius: 2px;
      padding: 3px;

      img {
        position: absolute;
        width: 90%;
        max-height: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      div {
        position: absolute;
        top: -5px; right: 0; bottom: 0; left: 0;
        color: red;
        font-size: 230%;
      }
    }
  }

}


.page-marker-with-activities {
  // override when only one or two activities
  &.one-two-activities {
    > div {
      width: 54px;

      .activity2 {
        left: 0;
      }

      .activity3 {
        right: 0;
      }
    }
  }

  &.this-page-activities {
    > div {
      width: 90px;
      height: 84px;
    }

    .activity2 {
      left: 14px;
    }
    .activity3 {
      right: 14px;
    }

    &.one-two-activities {
      > div {
        width: 80px;
        height: 80px;
      }
    }
  }

  > div {
    width: 80px;
    height: 65px;
    position: relative;

    .activity-box {
      position: absolute;
      width: 30px;
      height: 30px;
      border: 2px solid black;
      border-radius: 50%;
      background-color: white;

      img, svg {
        width: 80%;
        max-height: 80% !important;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      div {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        color: red;
        font-size: 300%;
      }
    }

    .activity1 {
      top: 17px; left: 0;
    }
    .activity2 {
      top: 0; left: 10px;
    }
    .activity3 {
      top: 0; right: 10px;
    }
    .activity4 {
      top: 17px; right: 0;
    }

    .main-marker {
      width: 36px;
      height: 48px;

      &.this-page-marker {
        width: 48px;
        height: 64px;

        div {
          width: 42px;
          height: 42px;
        }
      }

      background: center no-repeat;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);

      > svg {
        width: 100% !important;
        height: 100% !important;
      }

      div {
        position: absolute;
        width: 30px;
        height: 30px;
        left: 3px;
        top: 3px;
        border-radius: 50%;
        background: white;
        overflow: hidden;

        // this is for surrounding page markers (i.e. svg icons)
        svg {
          display: block;
          position: absolute;
          width: auto !important;
          height: 80% !important;
          max-width: 80%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        // this is for current page maker (i.e. logo centered in middle)
        img {
          width: 100%;
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

}