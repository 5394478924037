@import "variables.scss";

.static-container p {
    margin-bottom: 12px;
    max-width: 700px;
}
.static-container h4 {
    margin: 15px 0 10px 0;
}
.static-container li {
    max-width: 700px;
}

.static-page-wrapper {
    min-height: 100%;
    margin-bottom: -120px;
}
.static-page-push {
    height: 140px;
}

.docs-container {
  margin-bottom: 20px;

  h2 {
    margin: 20px 0 10px 0;
  }

  p {
    margin-bottom: 8px;
  }
}

.help-menu {
  margin-top: 20px;

  ul.tree,
  ul.tree ul {

    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.tree ul {
    margin-left: 1.0em;
  }

  ul.tree li {
    margin-left: 0.35em;
    border-left: thin solid #000;

    // fix for hanging text
    text-indent: -1em;
    padding-left: 1em;

  }

  ul.tree li:last-child {
    border-left: none;
  }

  ul.tree li:before {
    width: 0.9em;
    height: 0.6em;
    margin-right: 0.1em;
    vertical-align: top;
    border-bottom: thin solid #000;
    content: "";
    display: inline-block;
  }

  ul.tree li:last-child:before {
    border-left: thin solid #000;
  }

}

.help-container {
  color: rgb(0, 0, 0);
  color: rgba(0, 0, 0, 0.9);

  h1, h2, h3, h4 {
    margin: 35px 0 10px 0;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.71em;
  }

  h3 {
    font-size: 1.29em;
  }

  h4 {
    font-size: 1.14em;
  }

  p {
    margin-bottom: 15px;
  }

  ol, ul {
    margin-bottom: 30px;
    padding: 0 0 0 15px;

    li {
      margin-bottom: 15px;
    }
  }

  @media #{$bootstrap-break-sm} {
    h1 {
      font-size: 1.8em;
    }

    h2 {
      font-size: 1.6em;
    }

  }
}

.auth-box {
  display: inline-block;
  border: 1px solid $standard-grey;
  border-radius: $izele-border-radius;
  padding: 8px;
  margin-bottom: 20px;
}

.static-footer-logos {
  a:not(:last-child) {
    margin-right: 30px;

    @media #{$bootstrap-break-sm} {
      margin-right: 10px;
    }
  }

  img:not(.static-darwin) {
    width: 200px;

    @media #{$bootstrap-break-md} {
      width: 180px;
    }

    @media #{$bootstrap-break-sm} {
      width: 100px;
    }
  }

  img.static-darwin {
    height: 70px;

    @media #{$bootstrap-break-sm} {
      height: 50px;
    }
  }

}

.footer {
    position: relative;
    height: 140px;
    width: 100%;
}

.blue-footer {
    position: absolute;
    bottom: 0; left: 0; right: 0;
    background-color: #251F38;
    color: white;
}

.blue-footer-content {
    text-align: center;
    padding: 5px 0;

    a {
      color: white;
      margin: 0 20px;
    }

    @media #{$bootstrap-break-sm} {
      font-size: 90%;
    }
}


.partner-block {
  > div {
    width: 200px;
    height: 80px;
    vertical-align: top;
    margin-right: 20px;
    text-align: center;
    display: inline-block;
  }

  p {
    margin: 0;
  }

  img {
    margin: 0 !important;
  }
}