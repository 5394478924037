.set-country {
    color: black;
}
.set-country:hover {
    text-decoration: none;
    color: black;
}

.set-country img {
    max-width: 100px;
    height: 80px;
    float: left;
    margin-right: 8px;
}

.set-country div:first-child {
    border: 1px solid grey;
    margin-bottom: 8px;
    padding: 3px;
}
.set-country div:first-child:hover {
    box-shadow: 0 0 3px 3px rgba(0,0,0,0.1);
}

.set-country h4 {
    margin: 0;
}

/*re write so don't have to override as below*/
.set-country-type {
    position: absolute;
    right: 15px; bottom: 0;
    border: none !important;
    font-style: italic;
}
.set-country-type:hover {
    box-shadow: none !important;
}