@import "../_variables.scss";
@import "navigation.scss";


#page-banner {
  height: 275px;
  width: 100%;
  background: {
    size: cover;
    position: center;
    //repeat: no-repeat;
    repeat: repeat-y;
  }
  z-index: 98;
  position: relative;

  @media #{$bootstrap-break-md} {
    height: 200px;
  }

  @media #{$bootstrap-break-sm} {
    height: 170px;
  }

  @media #{$bootstrap-break-xs} {
    height: 120px;
  }

  .banner-gradient {
    position: absolute;
    width: 100%; height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(10, 10, 10, 0.8) 100%);
  }

  .page-logo-container {
    position: absolute;
    width: 100px;
    height: 100px;
    border: 1px solid grey;
    margin: 215px 0 0 20px;
    background-color: #ffffff;
    z-index: 100;

    img {
      max-width: 98px;
      max-height: 98px;
      margin: 0 auto; /* get rid of this once logo sizing sorted */
      display: block; /* and this */
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    @media #{$bootstrap-break-md} {
      width: 90px;
      height: 90px;
      margin: 95px 0 0 20px;

      img {
        max-width: 88px;
        max-height: 88px;
      }
    }

    @media #{$bootstrap-break-sm} {
      width: 70px;
      height: 70px;
      margin: 90px 0 0 10px;

      img {
        max-width: 68px;
        max-height: 68px;
      }
    }

    @media #{$bootstrap-break-xs} {
      width: 40px;
      height: 40px;
      margin: 58px 0 0 10px;

      img {
        max-width: 55px;
        max-height: 55px;
      }
    }
  }

  #page-title {
    position: absolute;
    color: #ffffff;
    left: 130px;
    bottom: 10px;
    right: 100px;
    max-width: 85%;

    h1 {
      margin: 0;
      color: #FFF;
      text-shadow: 0 0 8px #000;
    }

    @media #{$bootstrap-break-md} {
      h1 {
        font-size: 220%;
      }
    }

    @media #{$bootstrap-break-sm} {
      left: 95px;

      h1 {
        font-size: 200%;
      }
    }

    @media #{$bootstrap-break-xs} {
      left: 74px;
      bottom: 4px;

      h1 {
        font-size: 150%;
      }
    }
  }

  .banner-controls {
    position: relative;
    float: right;
    margin: 230px 20px 0 0;
    width: 120px;

    @media #{$bootstrap-break-md} {
      margin: 155px 20px 0 0;
    }

    @media #{$bootstrap-break-sm} {
      margin: 125px 5px 0 0;
    }

    @media #{$bootstrap-break-xs} {
      margin: 95px 3px 0 0;
    }
  }

  .banner-message {
    position: absolute;
    text-align: center;
    top: 5px; right: 0; left: 0;

    p {
      padding: 3px 7px;
      background-color: white;
      display: inline-block;

      @media #{$bootstrap-break-xs} {
        font-size: 80%;
      }
    }
  }
}


#org-content {
    //border: 1px solid red;
    position: absolute;
    top: 330px;
    bottom: 0;
    width: 100%;

    @media #{$bootstrap-break-md} {
      top: 255px;
    }

    @media #{$bootstrap-break-sm} {
      top: 225px;
    }

    @media #{$bootstrap-break-xs} {
      top: 170px;
    }
}


#ads-lg {
  width: 100%;
  min-height: 50px;
  text-align: center;

  h5 {
    font-size: 90%;
    margin: 5px 0 3px 0;
  }

  img {
    margin-top: 15px;
    max-width: 160px;
  }

}

.sidebar-new {
  border: 1px solid $standard-grey;
  border-radius: 4px;
  margin: 15px 0 20px 0;
  padding: 5px 5px 8px 5px;

  h4 {
    margin: 0;
  }

  p {
    margin: 4px 0;
  }
}


.controls-cover {
  // todo: make this a global?
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 9999;
  cursor: not-allowed;
}

.home-tab-image-container {
  width: 100%;
  max-height: 300px;
  overflow:hidden;
  margin-bottom: 10px;
}

#home-tab-image {
  width: 100%;
  height: 100%;
}